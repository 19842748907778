export default [
 

  
  {
    title: 'Educação',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Escolas',
        route: 'EscolasLista',
      },
      {
        title: 'Professores',
        route: 'ProfessorLista',
      },
      // {
      //   title: 'Profissionais',
      //   route: '',
      // },
      // {
      //   title: 'Patronos',
      //   route: 'ProfessorLista',
      // },
      {
        title: 'Alunos',
        route: 'Alunos',
      },
      
      
    ],
  },

  // {
  //   title: 'Saúde',
  //   icon: 'HeartIcon',
  //   children: [
  //     {
  //       title: 'Profissionais',
  //       route: '',
  //     },
  //     {
  //       title: 'Jovem ACE',
  //       route: '',
  //     },
      
      
  //   ],
  // },

  // {
  //   title: 'Pesquisa',
  //   icon: 'SearchIcon',
  //   children: [
      
  //     {
  //       title: 'Pesquisadores',
  //       route: '',
  //     },
      
      
  //   ],
  // },
  // {
  //   title: 'Agenda',
  //   icon: 'CalendarIcon',
  //   route: 'agenda',
  // },
  
  {
    title: 'Desafios',
    icon: 'BarChart2Icon',
    children: [
      // {
      //   title: 'Novo Desafio',
      //   route: 'selecionarmodelo',
      // },
      {
        title: 'Avaliar',
        route: 'avaliar',
      },
      // {
      //   title: 'Novo Desafio',
      //   route: 'novoDesafio',
      // },
      // {
      //   title: 'Todos os Desafios',
      //   route: 'todosdesafios',
      // },
      // {
      //   title: 'Modelos',
      //   route: 'modelos',
      // },
    ]
  },
  // {
  //   title: 'Ranking',
  //   icon: 'AwardIcon',
  //   route: '',
    
  // },
  // {
  //   title: 'Indicadores',
  //   icon: 'PieChartIcon',
  //   route: '',
  // },
  // {
  //   title: 'Relatórios',
  //   icon: 'FileTextIcon',
  //   route: 'relatorios',
  // },
]
